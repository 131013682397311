export enum StripePlanEnum {
  free = 'free',
  plus = 'plus',
  unlimited = 'unlimited',
}

export const StripePlansLabels: Record<StripePlanEnum, string> = {
  [StripePlanEnum.free]: 'Formule Gratuite',
  [StripePlanEnum.plus]: 'Formule Plus',
  [StripePlanEnum.unlimited]: 'Formule Illimitée',
};
