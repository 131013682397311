'use client';

import Carousel from '@/components/commons/carousel';
import Toggle from '@/components/commons/toggle';
import Card from '@/components/home/pricing/card';
import { HomePricingCarousel } from '@/contents/home-pricing-carousel';
import { clsx } from 'clsx';
import { memo } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  billing: boolean;
};

function PricingPageContent() {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormData>({ defaultValues: { billing: false } });

  const billingValue = watch('billing');

  return (
    <>
      <div className="flex items-center justify-center gap-3.5">
        <button
          type="button"
          onClick={() => {
            setValue('billing', !billingValue);
          }}
          className={clsx('text-lg leading-normal transition-all', {
            'font-semibold': !billingValue,
            'font-normal': billingValue,
          })}
        >
          Mensuel
        </button>
        <Toggle
          registered={register('billing')}
          error={errors.billing}
          alwaysPrimary={true}
          label="Changement de récurrence de paiement"
          hideLabel={true}
        />
        <button
          type="button"
          onClick={() => {
            setValue('billing', !billingValue);
          }}
          className={clsx('flex items-center gap-3.5 text-lg leading-normal transition-all', {
            'font-semibold': billingValue,
            'font-normal': !billingValue,
          })}
        >
          Annuel
          <span className="hidden rounded-lg bg-[#DEFFEC] px-[0.625rem] py-[0.375rem] font-medium text-[#0DB04E] text-base leading-[1.625] lg:block">
            Économisez 20%
          </span>
        </button>
      </div>

      <span className="mx-auto mt-[1.5625rem] block w-fit rounded-lg bg-[#DEFFEC] px-[0.625rem] py-[0.375rem] font-medium text-[#0DB04E] text-base leading-[1.625] lg:hidden">
        Économisez 20% en annuel
      </span>

      <div className="hidden lg:block">
        <ul className="mt-[1.75rem] grid grid-cols-3 gap-[2.0625rem]">
          {HomePricingCarousel.map((item) => (
            <li key={item.id}>
              <Card item={item} billing={billingValue ? 'yearly' : 'monthly'} />
            </li>
          ))}
        </ul>
      </div>

      <div className="-mx-5 -my-5 block lg:hidden">
        <Carousel
          items={HomePricingCarousel.map((item) => ({
            content: <Card key={item.id} item={item} billing={billingValue ? 'yearly' : 'monthly'} />,
          }))}
          carouselClass="h-[35rem] -mb-[3rem]"
          itemClass="py-10"
        />
      </div>
    </>
  );
}

export default memo(PricingPageContent);
