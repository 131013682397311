export const removeHash = () => {
  if (window.location.hash === '') {
    return;
  }

  let scrollV;
  let scrollH;
  const loc = window.location;

  if ('pushState' in history) {
    history.pushState('', document.title, loc.pathname + loc.search);
  } else {
    scrollV = document.body.scrollTop;
    scrollH = document.body.scrollLeft;

    loc.hash = '';

    document.body.scrollTop = scrollV;
    document.body.scrollLeft = scrollH;
  }
};
