import { clsx } from 'clsx';
import Link, { LinkProps } from 'next/link';
import { memo } from 'react';

function LinkButton({
  children,
  className,
  color = 'dodger-blue',
  ...props
}: { children: React.ReactNode; color?: 'dodger-blue' | 'white' } & Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  keyof LinkProps
> &
  LinkProps) {
  return (
    <Link
      className={clsx(
        'flex items-center justify-center gap-[10px] rounded-2xl px-5 py-3.5 font-medium text-base leading-6 transition-colors',
        {
          'bg-dodger-blue-500 text-white hover:bg-dodger-blue-600': color === 'dodger-blue',
          'bg-white text-black-text hover:bg-slate-300': color === 'white',
        },
        className ?? ''
      )}
      {...props}
    >
      {children}
    </Link>
  );
}

export default memo(LinkButton);
