import { PricingCarouselItem } from '@/models/home-pricing-item';
import { StripePlanEnum } from '@/models/stripe-plan.enum';

export const HomePricingCarousel: PricingCarouselItem[] = [
  {
    id: 'pricing-free',
    emoji: 'smilingFaceWithSunglasses',
    featuresColor: 'grey',
    pricingName: 'Lokappy',
    title: 'Gratuit',
    footerText: 'Aucune CB demandée',
    features: ['Limité à 1 bien', '2 locataires maximum', '50 mo de stockage maximum'],
    haloBoxShadow: '0 5px 50px 10px rgba(76, 94, 255, 0.15)',
    stripePlan: StripePlanEnum.free,
  },
  {
    id: 'pricing-plus',
    emoji: 'sparklingHeart',
    featuresColor: 'green',
    pricingName: 'Plus',
    monthlyPrice: 4.9,
    yearlyPrice: 49,
    footerText: 'Résiliable à tout moment',
    features: [
      'Limité à <strong>2 biens</strong>',
      'Locataires <strong>illimités</strong>',
      'Support client dédié',
      '<strong>5 go</strong> de stockage maximum',
    ],
    haloBoxShadow: '0 5px 50px 10px rgba(255, 89, 136, 0.15)',
    stripePlan: StripePlanEnum.plus,
  },
  {
    id: 'pricing-unlimited',
    emoji: 'crown',
    featuresColor: 'green',
    pricingName: 'Illimité',
    monthlyPrice: 9.9,
    yearlyPrice: 99,
    footerText: 'Résiliable à tout moment',
    features: [
      'Bien <strong>illimités</strong>',
      'Locataires <strong>illimités</strong>',
      'Support client dédié',
      '<strong>20 go</strong> de stockage maximum',
    ],
    haloBoxShadow: '0 5px 50px 10px rgba(255, 202, 13, 0.15)',
    stripePlan: StripePlanEnum.unlimited,
  },
];
