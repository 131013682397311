import { ReactInputProps } from '@/models/components-props/Input';
import { clsx } from 'clsx';
import { KeyboardEvent, useId, useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';

type LokaToggleProps = ReactInputProps & {
  error?: FieldError;
  registered: UseFormRegisterReturn;
  label?: string | React.ReactNode;
  alwaysPrimary?: boolean;
  hideLabel?: boolean;
};

export default function Toggle({ registered, label, alwaysPrimary, hideLabel = false, ...props }: LokaToggleProps) {
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const { ref, ...toggleForm } = registered;
  const id = useId();

  const toggleKeyDown = (event: KeyboardEvent<HTMLLabelElement>) => {
    if (event.key === ' ' && inputRef) {
      event.preventDefault();
    }
  };

  const toggleKeyUp = (event: KeyboardEvent<HTMLLabelElement>) => {
    if (event.key === ' ' && inputRef) {
      inputRef.click();
    }
  };

  return (
    <label
      className={clsx({
        'relative grid cursor-pointer grid-cols-[minmax(0,2.875rem)_minmax(0,1fr)] items-center gap-3':
          !!label && !hideLabel,
        'relative inline-flex cursor-pointer items-center': !label,
      })}
      htmlFor={id}
      tabIndex={0}
      onKeyUp={toggleKeyUp}
      onKeyDown={toggleKeyDown}
    >
      <input
        id={id}
        type="checkbox"
        role="switch"
        className="peer sr-only"
        {...props}
        {...toggleForm}
        tabIndex={-1}
        ref={(e) => {
          ref(e);
          setInputRef(e); // you can still assign to ref
        }}
      />
      <div
        className={clsx(
          "peer relative h-[1.625rem] w-[2.875rem] rounded-full after:absolute after:top-[0.25rem] after:left-[0.25rem] after:h-[1.125rem] after:w-[1.125rem] after:rounded-full after:bg-white after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white",
          {
            'bg-dodger-blue-500 peer-checked:bg-dodger-blue-500': alwaysPrimary,
            'bg-grey-light peer-checked:bg-dodger-blue-500': !alwaysPrimary,
          }
        )}
      />
      {label && (
        <span className="font-medium text-base text-black-text" hidden={hideLabel}>
          {label}
        </span>
      )}
    </label>
  );
}
