import Emoji from '@/components/commons/emoji';
import LinkButton from '@/components/commons/link-button';
import ArrowCircleRightIcon from '@/components/icons/arrow-circle-right';
import CheckCircleIcon from '@/components/icons/check-circle';
import { PricingCarouselItem } from '@/models/home-pricing-item';
import { clsx } from 'clsx';
import { memo } from 'react';
import CountUp from 'react-countup';

function Card({ item, billing }: { item: PricingCarouselItem; billing: 'yearly' | 'monthly' }) {
  return (
    <div
      className="grid h-[28.625rem] grid-rows-[minmax(0,min-content)_minmax(0,min-content)_minmax(0,min-content)_minmax(0,1fr)] rounded-[1.875rem] border border-grey-light bg-white px-5 pt-[1.5625rem] pb-5 lg:h-[28.8125rem]"
      style={{
        boxShadow: item.haloBoxShadow,
      }}
    >
      <h3 className="row-start-2 mb-[1.0625rem] font-bold text-[1.75rem] leading-normal lg:mb-[1.875rem] lg:text-3xl">
        {item.title ? (
          item.title
        ) : billing === 'yearly' && item.yearlyPrice ? (
          <CountUp end={item.yearlyPrice} duration={1} separator=" " decimals={2} decimal="," suffix="€" />
        ) : billing === 'monthly' && item.monthlyPrice ? (
          <CountUp end={item.monthlyPrice} duration={1} separator=" " decimals={2} decimal="," suffix="€" />
        ) : null}
        {!item.title && (
          <span className="ml-2 font-medium text-base leading-[2.25]">
            {billing === 'yearly' ? 'par an' : 'par mois'}
          </span>
        )}
      </h3>

      <div className="row-start-1 mb-[0.9375rem] flex items-center gap-3">
        <Emoji name={item.emoji} width={42} height={42} className="hidden lg:block" />
        <Emoji name={item.emoji} width={36} height={36} className="block lg:hidden" />
        <p className="font-semibold text-lg leading-normal">{item.pricingName}</p>
      </div>

      <ul className="grid gap-5">
        {item.features.map((feature) => (
          <li
            key={feature as string}
            className="grid grid-cols-[minmax(0,1.5rem)_minmax(0,1fr)] items-center gap-2 font-medium text-base leading-[1.625]"
          >
            <CheckCircleIcon
              width={24}
              height={24}
              className={clsx('self-start', {
                'text-green-500': item.featuresColor === 'green',
                'text-grey-dark': item.featuresColor === 'grey',
              })}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: feature,
              }}
            />
          </li>
        ))}
      </ul>

      <div className="self-end">
        <LinkButton href={`/sign-up?plan=${item.stripePlan}`}>
          Choisir cette offre
          <ArrowCircleRightIcon width={24} height={24} />
        </LinkButton>

        <p className="mt-3.5 text-center font-medium text-[0.875rem] text-grey-dark leading-normal lg:mt-[1.125rem]">
          {item.footerText}
        </p>
      </div>
    </div>
  );
}

export default memo(Card);
